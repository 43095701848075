import React from 'react';

import { PHRList } from '@palmhr/palmhr-ui-lib';

import CheckBox from 'components/forms/Checkbox/Checkbox';
import TextInput from 'components/forms/TextInput/TextInput';
import { PACKAGE_NAMES } from 'Customers/constants';
import { getSubscriptionPricingModels } from 'utility/pricingUtils';

const SubscriptionAddOnsList = ({ type, title, items, addonSelected, onSelectAddon, formik }) => {
  const renderItem = (item) => {
    const currentAddon = formik.values.addonSubscriptions.find((addon) => addon.plan === item.identifier);

    return (
      <div className='d-flex justify-content-between px-2' key={item.id}>
        <div className='d-flex mt-3' style={{ width: '100%' }}>
          <CheckBox
            value={item.id}
            onChange={(checked) => onSelectAddon(checked, item)}
            checked={addonSelected.includes(item.identifier)}
            name='addonSubscriptions'
            label={PACKAGE_NAMES[item.name] || item.name}
          />
        </div>
        <div className='d-flex flex-column justify-content-start align-items-end' style={{ width: '120px' }}>
          <TextInput
            placeholder={item.defaultUnitPrice}
            name={`addonSubscriptions.${item.identifier}.unitPrice`}
            type='number'
            value={currentAddon ? currentAddon.unitPrice : item.defaultUnitPrice}
            isRequired
            suffix={item.currency}
            isDisabled={!addonSelected.includes(item.identifier)}
            onChange={(e) => {
              const updatedAddons = formik.values.addonSubscriptions.map((addon) =>
                addon.plan === item.identifier ? { ...addon, unitPrice: e.target.value } : addon
              );
              formik.setFieldValue('addonSubscriptions', updatedAddons);
            }}
            onBlur={() => {
              if (!currentAddon?.unitPrice) {
                const updatedAddons = formik.values.addonSubscriptions.map((addon) =>
                  addon.plan === item.identifier ? { ...addon, unitPrice: item.defaultUnitPrice } : addon
                );
                formik.setFieldValue('addonSubscriptions', updatedAddons);
              }
            }}
          />
          <h6 className='font-size-12 text-gray text-end'>
            {getSubscriptionPricingModels(
              item.isSingleCharge,
              item.pricingUnit,
              item.pricingModel,
              item.defaultBillingPeriod
            )}
          </h6>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='px-4 py-3 mb-5' style={{ backgroundColor: type === 'primary' ? '#1a938a26' : '#D2A15326' }}>
        <h5
          className='mb-0'
          style={{ fontSize: '14px', fontWeight: 'bold', color: type === 'primary' ? '#1A938A' : '#D2A153' }}
        >
          {title}
        </h5>
      </div>
      <PHRList items={items} renderItem={renderItem} className='list-unstyled' />
    </div>
  );
};

export default SubscriptionAddOnsList;
